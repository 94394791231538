import React from "react";
import { Container } from "../../Shared/Container/Container";
import { SectionHeading } from "../../Shared/Typography/SectionHeading";
import Hammer from "./assets/hammer.png";
import { ServiceCard } from "./components/ServiceCard";
import { useServices } from "./hooks/useServices";

export interface HomeServicesProps {
  className?: string;
}

export const HomeServices = (props: HomeServicesProps) => {
  const { className = "" } = props;
  const services = useServices();

  return (
    <section id="services" className="py-16">
      <Container>
        <header>
          <SectionHeading>SLUŽBY</SectionHeading>
          <p className="">
            Profesionálna správcovská kancelária, k.s. sa špecializuje na
            poskytovanie komplexného poradenstva, odbornej podpory a služieb v
            oblasti insolvenčných procesov právnických i fyzických osôb ako aj
            procesov zrušovania a likvidácie obchodných spoločností.
          </p>
        </header>
        <div className="mt-8 services gap-4 lg:gap-10 space-y-4 lg:space-y-0 grid lg:grid-cols-3">
          {services.map((service) => (
            <ServiceCard {...service} />
          ))}
        </div>
      </Container>
    </section>
  );
};
