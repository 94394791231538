import { graphql, useStaticQuery } from "gatsby";

export const useHeroImage = () => {
  const { file } = useStaticQuery(graphql`
    query HeroImage {
      file(absolutePath: {}, relativePath: { eq: "home/hero/image.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return file;
};
