import React from "react";
import { LeftSide } from "../../Contact/LeftSide/LeftSide";
import { RightSide } from "../../Contact/RightSide/RightSide";
import { Container } from "../../Shared/Container/Container";

export interface HomeContactProps {
  className?: string;
}

export const HomeContact = (props: HomeContactProps) => {
  const { className = "" } = props;
  return (
    <section id="contact" className={`py-16`}>
      <Container>
        <div className="contact gap-8 xl:gap-10 grid grid-cols-1 xl:grid-cols-2">
          <LeftSide />
          <RightSide />
        </div>
      </Container>
    </section>
  );
};
