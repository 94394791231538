import { SectionHeading } from '../../Shared/Typography/SectionHeading';
import { HeaderSubSection } from './components/HeaderSubSection';
import React from "react";

export interface LeftSideProps {
  className?: string;
}

export const LeftSide = (props: LeftSideProps) => {
  const { className = '' } = props;
  return (
    <section className={`${className}`}>
      <SectionHeading>KONTAKT</SectionHeading>

      <div className='sections space-y-6'>
        <article className=''>
          <HeaderSubSection>Telefonický kontakt</HeaderSubSection>
          <p>+421 917 323 277 - (Po - Pia: 09:00 - 17:00)</p>
        </article>

        <article className=''>
          <HeaderSubSection>E-mail</HeaderSubSection>
          <p>psk@psk-ks.sk</p>
        </article>

        <article>
          <HeaderSubSection>Kancelária Bratislava</HeaderSubSection>
          <p>JUDr. Adrián Žák</p>
          <div>
            <p>Námestie slobody 28, 811 06 Bratislava</p>
            <p>azak@psk-ks.sk</p>
          </div>
        </article>

        <article>
          <HeaderSubSection>Kancelária Žilina</HeaderSubSection>
          <p>JUDr. Róbert Száraz</p>
          <div>
            <p>Sládkovičova 6, 010 01 Žilina</p>
            <p>rszaraz@psk-ks.sk</p>
          </div>
        </article>

        {/* <article>
          <HeaderSubSection>Kancelária Košice</HeaderSubSection>
          <p>JUDr. Tomáš Štefko</p>
          <div>
            <p>Alžbetina 41, 040 01 Košice</p>
            <p>tstefko@psk-ks.sk</p>
          </div>
        </article> */}
      </div>
    </section>
  );
};
