import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import { useAboutUsImage } from "../../Shared/AboutUs/hooks/useAboutUsImage";
import { Container } from "../../Shared/Container/Container";
import { ContentRenderer } from "../../Shared/ContentRenderer/ContentRenderer";
import { LogoLink } from "../../Shared/Header/components/LogoLink";
import { SectionHeading } from "../../Shared/Typography/SectionHeading";
import { test } from "./about.module.scss";
import { useAboutUs } from "./hooks/useAboutUs";

export interface HomeAboutUsProps {
  className?: string;
}

export const HomeAboutUs = (props: HomeAboutUsProps) => {
  const { className = "" } = props;
  const aboutUs = useAboutUs();

  const { file } = useStaticQuery(graphql`
    query HomeAboutUsImage {
      file(absolutePath: {}, relativePath: { eq: "home/about-us/tri.png" }) {
        childImageSharp {
          fixed(width: 329, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <section id="about-us" className="bg-section py-16">
      <Container className="flex lg:space-x-20 flex-col space-y-10 lg:space-y-0 lg:flex-row">
        <div className="flex flex-col flex-1">
          <SectionHeading>O NÁS</SectionHeading>
          <ContentRenderer source={aboutUs.content} />
        </div>
        <div className="imageside flex justify-center">
          <div
            style={{
              height: 396,
              width: 352,
            }}
            className="image relative"
          >
            <GatsbyImage fluid={aboutUs.image.childImageSharp.fluid} />
          <div className="logo absolute -bottom-14 -right-14 hidden lg:flex">
            <GatsbyImage fixed={file.childImageSharp.fixed} />
          </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
