import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { styled } from "linaria/react";
import { theme } from "../../Shared/Theme/Theme";

export interface GoogleMapProps extends React.HTMLAttributes<HTMLElement> {
  label: string;
  url: string;
}

const isClient = typeof window !== "undefined";

export const WhereToFindUsMap: React.FC<GoogleMapProps> = (
  props: GoogleMapProps
) => {
  const { label, url } = props;

  return (
    <div>
      <p className="mb-1">{label}</p>
      <section
        style={{
          height: 130,
          maxWidth: 400
        }}
        className={props.className + " bg-gray-50"}
      >
        {isClient && (
          <iframe
            src={url}
            frameBorder="0"
            allowFullScreen
            aria-hidden="false"
            className="w-full h-full"
            tabIndex={0}
          ></iframe>
        )}
      </section>
    </div>
  );
};
