import HammerImage from "../assets/hammer.png";
import Pie from "../assets/pie.png";
import Form from "../assets/form.png";

export interface Service {
  name: string;
  description: string;
  imageSrc: string;
}

export const useServices = (): Service[] => {
  return [
    {
      name: "Konkurzy",
      description:
        "Riešenia nepriaznivej ekonomickej situácie podnikateľských subjektov, ktoré vyústili až do konkurzu.",
      imageSrc: HammerImage,
    },
    {
      name: "Reštrukturalizácie",
      description:
        "Riešenie stavu úpadku, či hroziaceho úpadku podnikateľských subjektov, s cieľom zachovať ich ďalšiu existenciu.",
      imageSrc: Pie,
    },
    {
      name: "Osobné bankroty",
      description: `Poradenstvo pri konaniach
      o oddlžení fyzických osôb.`,
      imageSrc: Form,
    },
  ];
};
