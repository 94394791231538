import { graphql, useStaticQuery } from "gatsby";

export const useAboutUs = () => {
  const {
    strapiHomePage: { aboutUs },
  } = useStaticQuery(graphql`
    query aboutUs {
      strapiHomePage {
        aboutUs {
          content
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);
  return aboutUs;
};
