import React, { ReactNode } from "react";

export interface HeaderSubSectionProps {
  className?: string;
  children: ReactNode;
}

export const HeaderSubSection = (props: HeaderSubSectionProps) => {
  const { className = "", children } = props;
  return (
    <h5 className={`${className} text-primary text-base font-bold`}>
      {children}
    </h5>
  );
};
