import React from "react";
import { useContactLinks } from "../../Shared/Footer/hooks/useContactLinks";
import { SectionHeading } from "../../Shared/Typography/SectionHeading";
import { WhereToFindUsMap } from "../WhereToFindUs/WhereToFindUs";

export interface RightSideProps {
  className?: string;
}

export const RightSide = (props: RightSideProps) => {
  const { className = "" } = props;
  const contacts = useContactLinks();
  return (
    <div className={`className`}>
      <SectionHeading>KDE NÁS NÁJDETE</SectionHeading>
      <div className="maps space-y-6">
        <WhereToFindUsMap
          url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.1120273664765!2d17.10798321178354!3d48.151262555075526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c89507454154f%3A0x6c6475283f179a96!2sN%C3%A1mestie%20slobody%2028%2C%20811%2006%20Bratislava!5e0!3m2!1ssk!2ssk!4v1664466240588!5m2!1ssk!2ssk'
          label="Námestie slobody 28, 811 06 Bratislava"
        />
        <WhereToFindUsMap
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.753551461005!2d18.735873115687376!3d49.22420077932505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47145ea705f8071b%3A0x8ae66c80cda6b40d!2zU2zDoWRrb3ZpxI1vdmEgNiwgMDEwIDAxIMW9aWxpbmE!5e0!3m2!1sen!2ssk!4v1615454952693!5m2!1sen!2ssk"
          label="Sládkovičova 6, 010 01 Žilina"
        />
        {/* <WhereToFindUsMap
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2632.2601276657633!2d21.252210915669476!3d48.7196209792741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ee06f7998618b%3A0x13840bf37deb0bf3!2sAl%C5%BEbetina%2041%2C%20040%2001%20Ko%C5%A1ice!5e0!3m2!1sen!2ssk!4v1615455208213!5m2!1sen!2ssk"
          label="Alžbetina 41, 040 01 Košice"
        /> */}
      </div>
    </div>
  );
};