import React from "react";
import { LeftSide } from "../components/Contact/LeftSide/LeftSide";
import { RightSide } from "../components/Contact/RightSide/RightSide";
import { HomeAboutUs } from "../components/Home/AboutUs/AboutUs";
import { Hero } from "../components/Home/Hero/Hero";
import { HomeContact } from "../components/Home/HomeContact/HomeContact";
import { HomeMonetization } from "../components/Home/HomeMonetization/HomeMonetization";
import { HomeServices } from "../components/Home/HomeServices/HomeServices";
import { Container } from "../components/Shared/Container/Container";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const IndexPage = () => (
  <Layout>
    <SEO />
    <div className="content">
      <Hero />
      <HomeAboutUs />
      <HomeServices />
      <HomeMonetization />
      <HomeContact />
    </div>
  </Layout>
);

export default IndexPage;
