import BackgroundImage from "gatsby-background-image";
import React from "react";
import { Container } from "../../Shared/Container/Container";
import { useHeroImage } from "./hooks/useHeroImage";
import { imageBanner } from "./image-banner.module.scss";


export interface HeroProps {
  className?: string;
}

export const Hero = (props: HeroProps) => {
  const { className = "" } = props;

  const image = useHeroImage();

  return (
    <section className={`${className} relative`}>
      <BackgroundImage
        className={imageBanner}
        fluid={image.childImageSharp.fluid}
      >
        <div
          style={{
            height: 600,
          }}
        >
          <Container
            data-sal-duration="600"
            data-sal="slide-left"
            data-sal-easing="ease"
            className="flex flex-col justify-center h-full"
          >
            <h1 className="text-white font-bold text-3xl lg:text-6xl text-center">
              ZVERTE SA DO RÚK PROFESIONÁLOM
            </h1>
            {/* <p
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-delay={150 + 100}
              className="text-white text-lg mt-4"
            >
              Vaše starosti, naše riešenia, vzájomná spokojnosť
            </p> */}
          </Container>
        </div>
      </BackgroundImage>
    </section>
  );
};
