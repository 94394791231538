import React from "react";
import { Service } from "../hooks/useServices";

export interface ServiceCardProps extends Service {
  className?: string;
}

export const ServiceCard = (props: ServiceCardProps) => {
  const { className = "", name, description, imageSrc } = props;
  return (
    <article className={`flex space-x-4`}>
      <div className="image">
        <img style={{
          height: 50,
          minWidth: 50
        }} src={imageSrc} alt="" />
      </div>
      <div className="content">
        <h4 className="text-lg font-medium mb-1 text-primary">{name}</h4>
        <p>{description}</p>
      </div>
    </article>
  );
};
