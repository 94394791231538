import React from "react";
import { Container } from "../../Shared/Container/Container";
import LeftImage from "./assets/left.png";
import RightImage from "./assets/right.png";
import { Monetization } from "../../Shared/Monetization/Monetization";

export interface HomeMonetizationProps {
  className?: string;
}

export const HomeMonetization = (props: HomeMonetizationProps) => {
  const { className = "" } = props;
  return (
    <section style={{
    }} id="monetization" className={`py-16 max-w-screen  bg-section relative overflow-hidden`}>
      <div className="left absolute bottom-0 -left-24 2xl:left-0  hidden xl:flex">
        <img src={LeftImage} alt="" />
      </div>
      <Container>
        <Monetization />
      </Container>
      <div className="left absolute top-0 -right-24 2xl:right-0 hidden xl:flex">
        <img src={RightImage} alt="" />
      </div>
    </section>
  );
};
